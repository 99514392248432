<template>
    <main class="container">

        <form class="search-form" method="get" v-on:submit.prevent="find()">
            <div class="search-filed">
                <i class="ico ico-search2"></i>
                <input class="form-control" type="search" minlength="2" placeholder="Search" name="q" v-model="findQuery">
                <button class="btn-reset" type="reset"><i class="ico-close"></i></button>
            </div>
        </form>
        <ul class="nav nav-tabs search-tabs detail-tab allTabs" >
            <li><a href="javascript:;" :class="(type == 'all')? 'active': ''" @click="setType('all')">{{ $t("All") }}</a></li>
            <li><a href="javascript:;" :class="(type == 'teams')? 'active': ''" @click="setType('teams')">{{ $t("Teams") }}</a></li>
            <li><a href="javascript:;" :class="(type == 'players')? 'active': ''" @click="setType('players')">{{ $t("Players") }}</a></li>
            <li><a href="javascript:;" :class="(type == 'articles')? 'active': ''" @click="setType('articles')">{{ $t("Articles") }}</a></li>
            <!--<li><a data-toggle="tab" href="#tab04-05" role="tab" aria-selected="false">Videos</a></li>-->
        </ul>

        <div class="tab-content allContents">

            <div class="tab-pane fade show active">

                <div class="search-block teamss" v-if="searchedData.teams && (type == 'all' || type=='teams')">
                    <div class="search-block-head">
                        <h1>{{ $t("Teams") }}</h1>
                    </div>
                    <div class="league-cards-holder">
                        <div class="league-card"  v-for="team in searchedData.teams" :key="team.id">
                            <router-link :to="{ name: 'teamDetail.profile', params: {sport: $getMatchedSportsName(team.sport_id), team: team.slug} }" class="icon-logo"> <!-- Sport Value need to be FIXED !! -->
                                <img :alt="team.name" :src="team.flag">
                            </router-link>
                            <h2>
                                <router-link :to="{ name: 'teamDetail.profile', params: {sport: $getMatchedSportsName(team.sport_id), team: team.slug} }">{{team.name}}</router-link>
                            </h2>
                            <p>{{ team.stadium }}</p>
                            <div class="btn-wrap">
                                <router-link :to="{ name: 'teamDetail.fixture', params: {sport: $getMatchedSportsName(team.sport_id), team: team.slug} }" class="btn btn-outline-info" >Fixtures</router-link>
                            </div>
                        </div>
                        <p v-if="!searchedData.teams.length" class="ml-10">{{ $t("No Team Found") }}</p>
                    </div>
                </div>

                <div class="search-block playerss" v-if="searchedData.players && (type == 'all' || type=='players')">
                    <div class="search-block-head">
                        <h1>{{ $t("Players") }}</h1>
                    </div>
                    <div class="league-cards-holder">
                        <div class="league-card lg-player" v-for="player in searchedData.players" :key="player.id">
                            <router-link :to="{ name: 'playerDetail.profile', params: {sport: $getMatchedSportsName(player.sport_id), player: player.slug} }" class="icon-logo">
                                <img width="90" height="90" :src="player.avatar" :alt="player.name">
                            </router-link>
                            <h2>
                                <router-link :to="{ name: 'playerDetail.profile', params: {sport: $getMatchedSportsName(player.sport_id), player: player.slug} }">{{ player.name }}</router-link>
                            </h2>
                            <p>{{ player.nationality }}</p>
                            <div class="btn-wrap">
                                <router-link :to="{ name: 'playerDetail.stats', params: {sport: $getMatchedSportsName(player.sport_id), player: player.slug} }" class="btn btn-outline-info">Stats</router-link>
                            </div>
                        </div>
                        <p v-if="!searchedData.players.length" class="ml-10">{{ $t("No Player Found") }}</p>
                    </div>
                </div>

                <div class="search-block articless" v-if="searchedData.articles && (type == 'all' || type=='articles')">
                    <div class="search-block-head">
                        <h1>{{ $t("Articles") }}</h1>
                    </div>
                    <div class="key-post-hoilder">
                        <div class="key-post" v-for="article in searchedData.articles" :key="article.id">
                            <div class="img-holder">
                                <router-link :to="{ name: 'sport_news_detail', params: { sport: $getMatchedSportsName(article.sport_id), title: article.slug} }"><!--{{ route('newsDetail',[getSport(article.sport_id,'slug'),article.slug]) }}-->
                                    <img :src="article.image.image_path" width="279" height="171" :alt="article.image_caption">
                                </router-link>
                            </div>
                            <h3>
                                <router-link :to="{ name: 'sport_news_detail', params: { sport: $getMatchedSportsName(article.sport_id), title: article.slug} }">{{ article.headline }}</router-link>
                            </h3>
                            <ul class="meta2">
                                <li><span class="badge badge-green"></span></li><!-- competition -->
                                <li><time class="date" datetime="">{{ article.start_date | timeElaped }}</time></li><!-- {{ timeAgo($article.start_date) }}-->
                            </ul>
                        </div>
                        <p v-if="!searchedData.articles.length" class="ml-10">{{ $t("No Article Found") }}</p>
                    </div>
                </div>

            </div>

        </div>
        <br>

    </main>
</template>

<script>

    export default {
        name: "Find",
        data() {
            return{
                searchedData: {},
                type: 'all',
                findQuery: this.$route.query.q,
            }
        },
        mounted() {
            this.getSearchData();
        },
        serverPrefetch() {
            return this.getSearchData();
        },
        watch: {
            '$route.query.q'() {
                this.$parent.reload++;//rerender this component
            }
        },
        metaInfo() {
            let ss = this.$store.state.siteSetting;
            var replacements = {
                "%SEARCHED_QUERY%": this.$route.query.q,
            };

            let title = this.$getSafe(() => this.$replacePlaceHolders(ss.find_title,replacements));
            let description = this.$getSafe(() => this.$replacePlaceHolders(ss.find_description,replacements));
            let keywords = this.$getSafe(() => ss.find_meta_keywords);

            return {
                title: title,
                meta: this.$getMetaTags(title, description, keywords),
                link: [
                    {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}
                ],
            }
        },
        methods: {
            getSearchData() {
                let params = {q: this.$route.query.q};
                return this.$axios.get(this.$GET_SEARCHED, {params})
                        .then(response => {
                            if (response.data.result) {
                                this.searchedData = response.data.result;
                            }
                        })
                        .catch(error => console.log(error));
            },
            setType(type) {
                this.type = type;
            },
            find() {
                if (this.findQuery) {
                    this.$router.push({name: 'find', query: {q: this.findQuery}}).catch(() => {
                    })
                }
            }
        }
    }

</script>
